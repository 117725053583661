import React, { useContext, useEffect, useState } from 'react'
import styles from '../../Styles/Components/Services/EachServiceList.module.css'
import { BASE_URL_API } from '../../References/URLs';
import EachServicesCategory from './EachServicesCategory';
import EachServiceAddOns from './EachServiceAddOns';
import AuthContext from '../../store/auth-context';


const EachServiceList = () => {
    const authCtx = useContext(AuthContext)
    const [genderIdentifier, setGenderIdentifier] = useState(1);
    const [isFetchingCategories, setIsFetchingCategories] = useState(false)

    const [categories, setCategories] = useState([
        {
            "categoryIdentifier": 124,
            "categoryName": 'Bleach'
        },
        {
            "categoryIdentifier": 234,
            "categoryName": 'Facials'
        }
    ])

    const [selectedService, setSelectedService] = useState(null)

    const selectServiceHandler = (k) => {
        setSelectedService(k);
    }

    const getServiceCategories = async () => {
        setIsFetchingCategories(true)
        console.log(authCtx.token)
        const getServiceCategoriesResponse = await fetch(BASE_URL_API + "getServiceCategories",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    genderIdentifier: genderIdentifier
                })
            });

        if (!getServiceCategoriesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getServiceCategoriesRespo = await getServiceCategoriesResponse.json();
            if (getServiceCategoriesRespo.status === "success") {
                setCategories(getServiceCategoriesRespo.response);
            } else {
                if(getServiceCategoriesRespo.message==="tokenExpired") {
                    authCtx.logout()
                } else {
                    console.log(getServiceCategoriesRespo)
                }
            }
        }
        setIsFetchingCategories(false)
    }

    useEffect(() => {
        getServiceCategories();
    }, [genderIdentifier])



    return (
        <div className={styles.mainContainer}>
            <div className={styles.serviceListContainer}>
                {/* <div>Add services</div>
                <div>Salon Name</div>
                <div>Add Services</div> */}
                <div>
                    <div>
                        <button onClick={() => setGenderIdentifier(1)}
                            className={genderIdentifier == 1 ? styles.genderIdentifierBtnSelected : styles.genderIdentifierBtn}
                        >
                            Male
                        </button>
                        <button onClick={() => setGenderIdentifier(2)}
                            className={genderIdentifier == 2 ? styles.genderIdentifierBtnSelected : styles.genderIdentifierBtn}
                        >
                            Female
                        </button>
                    </div>
                    {isFetchingCategories == false ?
                        <div>
                            {categories != null ?
                                <div>
                                    {categories.map((item, index) => (
                                        <EachServicesCategory 
                                            item={item}
                                            key={index}
                                            selectServiceHandler={selectServiceHandler}
                                        />
                                    ))}
                                </div>
                                :
                                <div>
                                    No categories found for this gender!
                                </div>
                            }
                        </div>
                        :
                        <div>
                            Fetching categories...
                        </div>
                    }
                </div>
            </div>
            <div className={styles.addOnContainer}>
                <EachServiceAddOns selectedService={selectedService} />
            </div>
        </div>
    )
}

export default EachServiceList