import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import styles from '../Bookings/BookingDetails.module.css'
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { MdMale } from 'react-icons/md';
import { BsGenderMale } from 'react-icons/bs';
import { FaFemale, FaMale } from 'react-icons/fa';
import { FiMoreHorizontal } from 'react-icons/fi';
import ServicesListAtBD from '../../Components/Bookings/ServicesListAtBD';
import BookingActions from '../../Components/Bookings/BookingActions';
import { Link } from 'react-router-dom'
import Clock from '../../Helper/Clock.js';
import { ToastContainer } from 'react-toastify';

const BookingDetails = () => {
    const params = useParams();
    const bookingIdentifier = params.bookingIdentifier;
    const authCtx = useContext(AuthContext)
    const [bookingDetails, setBookingDetails] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [timeLapsed, setTimeLapsed] = useState([{
        "days": 0,
        "hours": 0,
        "minutes": 0,
        "seconds": 0
    }]);
    const [isBookingOptionsOpen, setIsBookingOptionsOpen] = useState(false)
    const toggleBookingOptions = () => setIsBookingOptionsOpen(false)

    const getBookingDetails = async () => {
        setIsLoading(true)
        const getBookingDetailsResponse = await fetch(BASE_URL_API + "getBookingDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: bookingIdentifier
                })
            });

        if (!getBookingDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getBookingDetailsRespo = await getBookingDetailsResponse.json();
            if (getBookingDetailsRespo.status === "success") {
                setBookingDetails(getBookingDetailsRespo.response)
            } else {
                if (getBookingDetailsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }

            console.log(getBookingDetailsRespo)
        }
        setIsLoading(false)
    }




    const timeLapsedClock = (k) => {
        const startingTime = new Date(k);

        if (!startingTime || isNaN(startingTime)) {
            console.log("Invalid starting time.");
            return;
        }

        let timerId = setInterval(() => {
            const currentTime = new Date();
            const timeLapsed = currentTime - startingTime;

            if (isNaN(timeLapsed)) {
                console.log("Error: Invalid time calculation.");
                clearInterval(timerId);
                return;
            }

            const seconds = Math.floor((timeLapsed / 1000) % 60);
            const minutes = Math.floor((timeLapsed / (1000 * 60)) % 60);
            const hours = Math.floor((timeLapsed / (1000 * 60 * 60)) % 24);
            const days = Math.floor(timeLapsed / (1000 * 60 * 60 * 24));

            // const timeLapsedString = `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds`;
            const timeLapsedString = [{
                "days": days,
                "hours": hours,
                "minutes": minutes,
                "seconds": seconds
            }];
            setTimeLapsed(timeLapsedString)
            //   console.log(`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds have elapsed since ${startingTime}.`);
            // return `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds have elapsed since ${startingTime}.`;
        }, 1000);
    }

    useEffect(() => {
        getBookingDetails()
    }, [bookingIdentifier])

    useEffect(() => {
        if (bookingDetails.createdOn) {
            timeLapsedClock(bookingDetails.createdOn)
        }
    }, [bookingDetails])


    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            {isBookingOptionsOpen &&
                <BookingActions
                    isOpen={isBookingOptionsOpen}
                    toggleBookingOptions={toggleBookingOptions}
                    bookingIdentifier={bookingIdentifier}
                    bookingDetails={bookingDetails}
                />
            }
            <ToastContainer />
            <div className={styles.mainContainer}>
                <div className={styles.mainHeader}>
                    <div className={styles.navigationBtn}>
                        <div className={styles.leftCol}>
                            <div className={styles.bookingDetails}>
                                BookingDetails : {bookingDetails.bookingId}
                            </div>
                        </div>
                        <div className={styles.rightCol}>
                            <Link to="/bookings" className={styles.activeBookings}>10+ active bookings</Link>
                            <div className={styles.moreBtn} onClick={() => setIsBookingOptionsOpen(true)} >
                                <FiMoreHorizontal />
                            </div>
                        </div>
                    </div>
                    <div className={styles.bookingSummary}>
                        <div>
                            <div>
                                <div className={styles.clientName}>
                                    {bookingDetails.client_name}
                                </div>
                                <div className={styles.gender}>
                                    {bookingDetails.gender}
                                </div>
                            </div>
                            <div className={styles.visitorCountWrapper}>
                                <div className={styles.eachVCBox}>
                                    <FaFemale size={24} color="#00000080" />
                                    <div className={styles.counterBadge}>
                                        {bookingDetails.femaleQuant}
                                    </div>
                                </div>
                                <div className={styles.eachVCBox}>
                                    <FaMale size={24} color="#00000080" />
                                    <div className={styles.counterBadge}>
                                        {bookingDetails.maleQuant}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.timeLapsedWrapper}>
                            <div className={styles.timeLapsedHeader}>
                                Minutes in Store
                            </div>
                            <div className={styles.timeLapsedBody}>
                                <div className={styles.eachTLUnit}>
                                    <div className={styles.tlUnitValue}>{timeLapsed[0].days}</div>
                                    <div className={styles.tlUnitCaption}>Days</div>
                                </div>
                                <div className={styles.eachTLUnit}>
                                    <div className={styles.tlUnitValue}>{timeLapsed[0].hours}</div>
                                    <div className={styles.tlUnitCaption}>Hours</div>
                                </div>
                                <div className={styles.eachTLUnit}>
                                    <div className={styles.tlUnitValue}>{timeLapsed[0].minutes}</div>
                                    <div className={styles.tlUnitCaption}>Minutes</div>
                                </div>
                                <div className={styles.eachTLUnit}>
                                    <div className={styles.tlUnitValue}>{timeLapsed[0].seconds}</div>
                                    <div className={styles.tlUnitCaption}>Seconds</div>
                                </div>
                            </div>
                            {/* <Clock width="100px" height="100px" interval={100} borderWidth={8} fillSmoothness={0.1} /> */}
                        </div>
                    </div>
                    <div className={styles.actionBtns}>
                        <button>Services</button>
                        <button>Payment Details</button>
                        <button>Feedback</button>
                    </div>
                </div>
                {!isLoading &&
                    <div className={styles.mainBody}>
                        <ServicesListAtBD bookingDetails={bookingDetails} />
                    </div>
                }
            </div>
        </div>
    )
}

export default BookingDetails