import React, { useContext, useEffect, useState } from 'react'
import styles from '../AuthPages/Login.module.css'
import { BsEyeFill, BsEyeSlashFill } from "react-icons/bs";
import { sanitizeRoute } from '../../Helper/calculations';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';

const Login = () => {
    const authCtx = useContext(AuthContext);
    const [isPasswordVisible, setIsPasswordVisible] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const enterUsernameHandler = (k) => {
        setUsername(sanitizeRoute(k));
    }
    const [isAuthenticating, setIsAuthenticating] = useState(false)
    const [responseMessage, setResponseMessage] = useState('Login Here!')

    const loginHandler = async () => {
        if (username !== '' && password !== '') {
            setIsAuthenticating(true)
            setResponseMessage('Please wait...')
            const loginHandlerResponse = await fetch(BASE_URL_API + `auth`,
                {
                    method: 'POST',
                    body: JSON.stringify({
                        username: username,
                        password: password
                    })
                });

            if (!loginHandlerResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const loginHandlerRespo = await loginHandlerResponse.json();
                if (loginHandlerRespo.status === 'success') {
                    authCtx.login(loginHandlerRespo.token, loginHandlerRespo.storeIdentifier);
                } else {
                    setResponseMessage('Invalid username or password!')
                    console.log(loginHandlerRespo);
                }
            }
            setIsAuthenticating(false)
        } else {
            alert("Please enter valid details");
        }
    }

    useEffect(() => {
        const metaThemeColor = document.querySelector("meta[name='theme-color']");
        if (metaThemeColor) {
            metaThemeColor.setAttribute("content", "#f7f7f7");
        } else {
            const meta = document.createElement("meta");
            meta.name = "theme-color";
            meta.content = "#f7f7f7";
            document.head.appendChild(meta);
        }
    }, []);

    return (
        <div className={styles.mainWrapper}>
            <div className={styles.leftSection}>
                <div className={styles.leftContainer}>
                    <h2>SALON SCHEDULING SOFTWARE</h2>
                    <h3>Take Control of Your Calendar</h3>

                    A Beautiful Experience for You & Your Customers
                    <p>
                        Get discovered by new clients with salon software by Lokaci. 
                        Fill your schedule with client appointments and get paid quickly & easily.
                        Collect feedbacks / reviews.
                    </p>
                </div>

            </div>
            <div className={styles.rightSection}>
                <div className={styles.loginSection}>
                    <div className={styles.loginResponse}>
                        {responseMessage}
                    </div>
                    <div className={styles.inputWrapper}>
                        <input placeholder="Please enter e-mail or phone"
                            value={username}
                            onChange={(e) => enterUsernameHandler(e.target.value)}
                        ></input>
                    </div>
                    <div className={styles.passwordWrapper}>
                        <div className={styles.passwordContainer}>
                            <input placeholder="Please enter password"
                                type={isPasswordVisible === true ? "text" : "password"}
                                autoComplete="off"
                                autoFocus={true}
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            ></input>
                            <div onClick={() => {
                                setResponseMessage('Login Here!')
                                setIsPasswordVisible(!isPasswordVisible)
                            }}
                                className={styles.togglePass}
                            >
                                {isPasswordVisible === true ?
                                    <BsEyeFill color="black" />
                                    :
                                    <BsEyeSlashFill color="black" />
                                }
                            </div>
                        </div>
                    </div>
                    <div className={styles.loginBtnWrapper}>
                        {isAuthenticating === true ?
                            <button>
                                Authenticating...
                            </button>
                            :
                            <button onClick={() => loginHandler()}>Login</button>
                        }
                    </div>
                    <div>
                        <div className={styles.dividerLineWrapper}>
                            <div className={styles.justLine}></div>
                            <div className={styles.txtBetweenLine}>
                                or register
                            </div>
                            <div className={styles.justLine}></div>
                        </div>
                        <div>
                            <div>
                                Via Google
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Login