import React, { useContext, useEffect, useState } from 'react'
import { BiCalendar, BiRupee } from 'react-icons/bi';
import { GiTakeMyMoney } from 'react-icons/gi';
import { FiMoreVertical } from 'react-icons/fi';
import { HiUsers } from 'react-icons/hi';
import { RiUserReceivedFill } from 'react-icons/ri';
import { MdCardMembership } from 'react-icons/md';
import NavAtHome from '../../Components/Navigators/NavAtHome';
import AuthContext from '../../store/auth-context'
import styles from '../Pages/Home.module.css'
import { BASE_URL_API } from '../../References/URLs';
import { Link } from 'react-router-dom';
import CSEachBookings from '../../Components/Bookings/CSEachBookings';
import EachBookingAtHome from '../../Components/Home/EachBookingAtHome';
import DateRangeSelector from '../../Components/UI/DateRangeSelector';

const Home = () => {
    const authCtx = useContext(AuthContext);
    const [collectionSummary, setCollectionSummary] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    const [isDatePickerOpen, setIsDatePickerOpen] = useState(false)
    const closeModal = () => { setIsDatePickerOpen(false) }
    const [dates, setDates] = useState({
        "startDate": "",
        "endDate": ""
    })

    const changeDateHandler = (s, e) => {
        setDates({
            "startDate": s,
            "endDate": e
        })
    }

    const getCollectionSummary = async () => {
        setIsLoading(true)
        const getCollectionSummaryResponse = await fetch(BASE_URL_API + "getCollectionSummary",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier,
                    startDate: dates.startDate,
                    endDate: dates.endDate
                })
            });

        if (!getCollectionSummaryResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCollectionSummaryRespo = await getCollectionSummaryResponse.json();
            if (getCollectionSummaryRespo.status === 'success') {
                setCollectionSummary(getCollectionSummaryRespo.response);
            } else {
                if (getCollectionSummaryRespo.message === "tokenExpired") {
                    authCtx.logout()
                } else {
                    setCollectionSummary({
                        "totalCollection": 0,
                        "totalTransactions": 0,
                        "allTransactions": []
                    });
                }
            }
            console.log(getCollectionSummaryRespo)
        }
        setIsLoading(false)
    }

    useEffect(() => {
        getCollectionSummary()
    }, [])

    useEffect(() => {
        getCollectionSummary()
    }, [dates])



    return (
        <div className={styles.mainWrapper}>
            <div className={styles.mainContainer}>
                <NavAtHome />
                <div className={styles.bodyContainer}>
                    <div className={styles.leftWrapper}>
                        <div className={styles.titleWrapper}>
                            <div className={styles.welcomeTxt}>
                                Welcome to store0001
                            </div>
                            <div className={styles.dateRangeSelectorWrapperBtn}
                                onClick={() => setIsDatePickerOpen(!isDatePickerOpen)}
                            >
                                {dates.startDate === dates.endDate ?
                                    <div>
                                        {dates.startDate} &nbsp;
                                    </div>
                                    :
                                    <div>
                                        From {dates.startDate} to {dates.endDate} &nbsp;
                                    </div>
                                }

                                <BiCalendar />
                            </div>
                            <DateRangeSelector
                                isOpen={isDatePickerOpen}
                                closeModal={closeModal}
                                changeDateHandler={changeDateHandler}
                            />
                        </div>

                        <div className={styles.contentWrapper}>
                            {isLoading === true ?
                                <div className={styles.summaryBlockWrapper}>

                                </div>
                                :
                                <div className={styles.summaryBlockWrapper}>
                                    <div className={styles.summaryBlockWrapperUpper}>
                                        <div className={styles.eachOption}>
                                            <div className={styles.iconsRow}>
                                                <div>
                                                    <GiTakeMyMoney size={28} />
                                                </div>
                                                <div>
                                                    <FiMoreVertical size={24} />
                                                </div>
                                            </div>
                                            <div className={styles.rupeeIcon}>
                                                <BiRupee />
                                                {parseFloat(collectionSummary.totalCollection).toFixed(1)}
                                            </div>
                                            <div className={styles.optionCaption}>
                                                Total collection
                                            </div>
                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.iconsRow}>
                                                <div>
                                                    <HiUsers size={28} />
                                                </div>
                                                <div>
                                                    <FiMoreVertical size={24} />
                                                </div>
                                            </div>
                                            {isLoading === true ?
                                                <div className={styles.rupeeIcon}>
                                                    -
                                                </div>
                                                :
                                                <div className={styles.rupeeIcon}>
                                                    {collectionSummary.totalTransactions}
                                                </div>
                                            }
                                            <div className={styles.optionCaption}>
                                                Bookings
                                            </div>
                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.iconsRow}>
                                                <div>
                                                    <RiUserReceivedFill size={28} />
                                                </div>
                                                <div>
                                                    <FiMoreVertical size={24} />
                                                </div>
                                            </div>
                                            <div className={styles.rupeeIcon}>
                                                -
                                            </div>
                                            <div className={styles.optionCaption}>
                                                New Clients
                                            </div>

                                        </div>
                                        <div className={styles.eachOption}>
                                            <div className={styles.iconsRow}>
                                                <div>
                                                    <MdCardMembership size={28} />
                                                </div>
                                                <div>
                                                    <FiMoreVertical size={24} />
                                                </div>
                                            </div>
                                            <div className={styles.rupeeIcon}>
                                                {collectionSummary?.cardsSold?.length || 0}
                                            </div>
                                            <div className={styles.optionCaption}>
                                                Memberships Sold
                                            </div>

                                        </div>
                                    </div>

                                    <div className={styles.summaryBlockWrapperTile}>
                                        <div className={styles.eachTile}>
                                            <div className={styles.tileTitle}>
                                                Total Cash Collection
                                            </div>
                                            <div>
                                                ₹{isNaN(collectionSummary?.collectionViaCash) ? 0 : parseFloat(collectionSummary?.collectionViaCash).toFixed(1)}
                                            </div>
                                        </div>
                                        <div className={styles.eachTile}>
                                            <div className={styles.tileTitle}>
                                                Total QR Collection
                                            </div>
                                            <div>
                                                ₹{isNaN(collectionSummary?.collectionViaQr) ? 0 : parseFloat(collectionSummary?.collectionViaQr).toFixed(1)}
                                            </div>
                                        </div>
                                        <div className={styles.eachTile}>
                                            <div className={styles.tileTitle}>
                                                Total Cards Payment Collection
                                            </div>
                                            <div>
                                                ₹{isNaN(collectionSummary?.collectionViaCard) ? 0 : parseFloat(collectionSummary?.collectionViaCard).toFixed(1)}
                                            </div>
                                        </div>
                                        <div className={styles.eachTile}>
                                            <div className={styles.tileTitle}>
                                                Total Gift Cards Collection
                                            </div>
                                            <div>
                                                ₹{isNaN(collectionSummary?.collectionViaGiftCards) ? 0 : parseFloat(collectionSummary?.collectionViaGiftCards).toFixed(1)}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            }


                            {isLoading === true ?
                                <div>
                                    Loading...
                                </div>
                                :
                                <div className={styles.allTransactionsWrapper}>
                                    <div>
                                        All Transactions
                                    </div>
                                    <div className={styles.allTransactionsContainer}>
                                        {collectionSummary.allTransactions.map((item, index) => (
                                            <EachBookingAtHome item={item} key={index} />
                                        ))}
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                    <div className={styles.rightWrapper}>

                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home