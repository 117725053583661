import React, { useEffect, useState } from 'react'
import styles from '../UI/DateRangeSelector.module.css'


import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const DateRangeSelector = props => {
    const isOpen = props.isOpen;
    const closeModal = props.closeModal;
    const changeDateHandler = props.changeDateHandler;

    window.onclick = function (event) {
        if (event.target.id == "dateRangeSelector") {
            closeModal();
        }

        if (event.target.id == "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }

    }

    document.addEventListener('keydown', function (e) {
        if (e.keyCode == 27) {
            closeModal();
        }
    });



    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());


    // console.log(startDate?.toISOString()?.substring(0, 10), endDate?.toISOString()?.substring(0, 10))

    const handleStartChange = (date) => {
        setStartDate(date);

    };

    const handleEndChange = (date) => {
        setEndDate(date);

    };


    const highlightDates = (date) => {
        if (startDate && endDate) {
            return date >= startDate && date <= endDate;
        } else {
            return false;
        }
    };

    useEffect(() => {
        changeDateHandler(new Date(startDate.getTime() - (startDate.getTimezoneOffset() * 60000))?.toISOString()?.substring(0, 10), new Date(endDate.getTime() - (endDate.getTimezoneOffset() * 60000))?.toISOString()?.substring(0, 10));
    }, [startDate, endDate])





    if (!isOpen) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    id="dateRangeSelector"
                >
                    <div className={styles.mainCalendarBox}>
                        <div className={styles.calendarContainer}>
                            <div className={styles.ccLeft}>
                                <div className={styles.eachBtnWrapper}>
                                    Today
                                </div>
                                <div className={styles.eachBtnWrapper}>
                                    Yesterday
                                </div>
                                <div className={styles.eachBtnWrapper}>
                                    This Week
                                </div>
                                <div className={styles.eachBtnWrapper}>
                                    Last Week
                                </div>
                                <div className={styles.eachBtnWrapper}>
                                    This Month
                                </div>
                                <div className={styles.eachBtnWrapper}>
                                    Last Month
                                </div>
                            </div>
                            <div className={styles.ccRight}>
                                <div className={styles.dateHeader}>
                                    <div className={styles.eachDateWrapper}>
                                        <div className={styles.eachCalTitle}>Start Date</div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={handleStartChange}
                                            selectsStart
                                            startDate={startDate}
                                            endDate={endDate}
                                            highlightDates={highlightDates}
                                            inline={true}
                                            calendarClassName={styles.eachCalendar}
                                        />
                                    </div>
                                    <div className={styles.eachDateWrapper}>
                                        <div className={styles.eachCalTitle}>End Date</div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={handleEndChange}
                                            selectsEnd
                                            startDate={startDate}
                                            endDate={endDate}
                                            minDate={startDate}
                                            highlightDates={highlightDates}
                                            inline={true}
                                            calendarClassName={styles.eachCalendar}
                                        />
                                    </div>
                                </div>
                                {/* <button onClick={() => closeModal()} >Close</button> */}
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        )
    }

}

export default DateRangeSelector