import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import SessionInitiator from '../../Components/Sessions/SessionInitiator'
import styles from '../Sessions/ActiveSessions.module.css'
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import PriceList from '../../Components/Sessions/PriceList';
import { Link } from 'react-router-dom'
import { humanReadableTimePassed } from '../../Helper/calculations';

const ActiveSessions = props => {
    let params = useParams();
    const sessionIdentifier = params.sessionIdentifier;
    const [openModal, setOpenModal] = useState(false)

    const authCtx = useContext(AuthContext);
    const [currentSessions, setCurrentSessions] = useState([]);
    const [completedSessions, setCompletedSessions] = useState([])

    const [workersData, setWorkersData] = useState([])

    const initiateBooking = () => {
        setOpenModal(true)
    }

    const abortInitiation = () => {
        setOpenModal(false)
    }

    const getCurrentSessions = async () => {
        const getCurrentSessionsResponse = await fetch(BASE_URL_API + "getCurrentSessions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier
                })
            });

        if (!getCurrentSessionsResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const getCurrentSessionsRespo = await getCurrentSessionsResponse.json();
            if (getCurrentSessionsRespo.status === "success") {
                getCurrentSessionsRespo.response[0] !== null ? setCurrentSessions(getCurrentSessionsRespo.response[0]) : setCurrentSessions([])
                getCurrentSessionsRespo.response[1] !== null ? setCompletedSessions(getCurrentSessionsRespo.response[1]) : setCompletedSessions([]);

            } else {
                if (getCurrentSessionsRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
            }
            console.log(getCurrentSessionsRespo);

        }
    }

    useEffect(() => {
        getCurrentSessions()
    }, [sessionIdentifier])



    const getWorkersData = async () => {
        const getWorkersDataResponse = await fetch(BASE_URL_API + "getWorkersData",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier
                })
            });

        if (!getWorkersDataResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getWorkersDataRespo = await getWorkersDataResponse.json();
            if (getWorkersDataRespo.status === 'success') {
                setWorkersData(getWorkersDataRespo.response)
            } else {
                if (getWorkersDataRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            // console.log(getWorkersDataRespo)
        }
    }

    useEffect(() => {
        getWorkersData()
    }, [sessionIdentifier])


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.sessionStoreWrapper}>
                <div className={styles.initiateBookingBtn}>
                    <button onClick={() => initiateBooking()}>Start A Booking</button>
                </div>
                {currentSessions.map((item, index) => (
                    <div className={styles.eachSessionWrapper} key={index}>
                        <Link to={`/sessions/` + item.bookingDetails.bookingId} >
                            <div className={styles.startedOnWrapper}>
                                <div>Started On</div>
                                <div>{ humanReadableTimePassed(item?.bookingDetails?.createdOn)}</div>
                            </div>
                            <div>
                                <div>
                                    {item?.bookingDetails?.clientName}
                                </div>
                                <div>
                                    {item?.bookingDetails?.bookingId}
                                </div>
                            </div>
                            <div className={styles.clientCount}>
                                <div>
                                    Female :
                                    {item.bookingDetails.femaleQuant}
                                </div>
                                <div>
                                    Male :
                                    {item.bookingDetails.maleQuant}
                                </div>
                                <div>
                                    Others :
                                    {item.bookingDetails.othersQuant}
                                </div>
                            </div>
                            <div className={styles.servicesSection}>
                                <div>
                                    {item?.allServices?.map((itemC, indexC) => (
                                        <div key={indexC} className={styles.eachService}>
                                            {itemC?.categoryName} - {itemC?.serviceName}
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className={styles.sessionDetailsWrapper}>
                <PriceList
                    sessionIdentifier={sessionIdentifier}
                    workersData={workersData}
                />
                <NavAtHome />
                <SessionInitiator
                    isOpen={openModal}
                    abortInitiation={abortInitiation}
                    initiateBooking={initiateBooking}
                />

            </div>
        </div>
    )
}

export default ActiveSessions