export const sanitizeRoute = (e) => {
    let p = e.trim();
    p = p.replace(/\s/g, '');
    p = p.replace(/\//g, '');
    p = p.replace(/\$/g, '');
    p = p.replace(/\?/g, '');
    p = p.replace(/\!/g, '');
    // p = p.replace(/\@/g, '');
    p = p.replace(/\#/g, '');
    p = p.replace(/\%/g, '');
    p = p.replace(/\^/g, '');
    p = p.replace(/\&/g, '');
    p = p.replace(/\*/g, '');
    p = p.replace(/\(/g, '');
    p = p.replace(/\)/g, '');
    p = p.replace(/\=/g, '');
    p = p.replace(/\+/g, '');
    p = p.replace(/\`/g, '');
    p = p.replace(/\~/g, '');
    p = p.replace(/\|/g, '');
    p = p.replace(/\\/g, '');
    p = p.replace(/\[/g, '');
    p = p.replace(/\]/g, '');
    p = p.replace(/\{/g, '');
    p = p.replace(/\}/g, '');
    p = p.replace(/\:/g, '');
    p = p.replace(/\;/g, '');
    p = p.replace(/\'/g, '');
    p = p.replace(/\"/g, '');
    // p = p.replace(/\./g, '');
    p = p.replace(/\,/g, '');
    p = p.replace(/\>/g, '');
    p = p.replace(/\</g, '');
    p = p.toLowerCase();
    // p = getRidofDuplication(p);
    return p;
}


function getRidofDuplication(key) {
    if (key.length > 1) {
        let lKey = key.charAt(key.length - 1);
        let slKey = key.charAt(key.length - 2);
        if (lKey == "-" || lKey == "_" || lKey == "=" || lKey == "-_" || lKey == "_-" || lKey == "=_" || lKey == "_=" || lKey == "-=" || lKey == "=-" || lKey == "_-_") {
            if (lKey == slKey) {
                key = key.slice(0, -1);
            }
            return key;
        } else {
            return key;
        }
    } else {
        return key;
    }
}

export const timeLapsedClockFromRef = (k) => {
    const startingTime = new Date(k);

    setInterval(() => {
        const currentTime = new Date();
        const timeLapsed = currentTime - startingTime;

        const seconds = Math.floor((timeLapsed / 1000) % 60);
        const minutes = Math.floor((timeLapsed / (1000 * 60)) % 60);
        const hours = Math.floor((timeLapsed / (1000 * 60 * 60)) % 24);
        const days = Math.floor(timeLapsed / (1000 * 60 * 60 * 24));

        return "ok";
        //   console.log(`${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds have elapsed since ${startingTime}.`);
        // return `${days} days, ${hours} hours, ${minutes} minutes, and ${seconds} seconds have elapsed since ${startingTime}.`;
    }, 1000);
}


export const timeLeftInFuture = (k) => {
    let today = new Date();
    let targetDate = new Date(k);
    let timeDifference = targetDate.getTime() - today.getTime();
    let remainingDays = Math.ceil(timeDifference / (1000 * 3600 * 24));
    return remainingDays;
}


export const dateDifference = (k) => {
    let date1 = new Date(k)
    const date2 = new Date();

    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    const diffMonths = Math.floor(diffDays / 30.44);
    const diffYears = Math.floor(diffMonths / 12);

    return {
        years: diffYears,
        months: diffMonths % 12,
        days: parseInt(diffDays % 30.44)
    };
    // console.log(`${result.years} years, ${result.months} months, and ${result.days} days`);
}

export const toDayMonthYear = (k) => {
    let date = k.split(" ")[0];
    let dd = date.split("-")[2];
    let mm = date.split("-")[1];
    let yyyy = date.split("-")[0];

    let returningArr = [dd, mm, yyyy];

    return returningArr;

}

export const monthNumToTxtSmall = (k) => {
    if(k==='01') { return 'Jan' }
    if(k==='02') { return 'Feb' }
    if(k==='03') { return 'Mar' }
    if(k==='04') { return 'Apr' }
    if(k==='05') { return 'May' }
    if(k==='06') { return 'Jun' }
    if(k==='07') { return 'Jul' }
    if(k==='08') { return 'Aug' }
    if(k==='09') { return 'Sep' }
    if(k==='10') { return 'Oct' }
    if(k==='11') { return 'Nov' }
    if(k==='12') { return 'Dec' }
}


export const humanReadableTimePassed = (k) => {
    let curTime = new Date();
    curTime = curTime.toISOString();
    let recTime = new Date(k).toISOString();
    let diff = new Date(curTime) - new Date(recTime);

    let days = Math.floor(diff / (24 * 60 * 60 * 1000));
    let hours = Math.floor((diff - days * (24 * 60 * 60 * 1000)) / (60 * 60 * 1000));

    // let minutes = Math.floor(diff - ((diff - days * (24 * 60 * 60 * 1000)) / ( 60*1000)) );

    let minutes = Math.floor((diff / (1000 * 60)) % 60)


    // console.log("Minutes : ",minutes);

    let returningItem = '';
    if (days < 1) {
        if (hours < 1) {
            returningItem = minutes + " mins ago";
        } else if (hours < 5) {
            returningItem = hours + " hours " + minutes + " mins ago";
        } else if (hours < 12) {
            returningItem = hours + " hours ago";
        } else if (hours < 14) {
            returningItem = " today";
        } else {
            returningItem = " yesterday";
        }
    } else if (days < 30) {
        if (days < 7) {
            returningItem = days + " days ago";
        } else if (days > 6) {
            returningItem = " around a week ago";
        } else if (days < 13) {
            returningItem = days + " days ago";
        } else if (days < 15) {
            returningItem = " 2 weeks ago";
        } else if (days > 15) {
            returningItem = " 3 weeks ago";
        } else {
            returningItem = " around a month ago";
        }
    } else if (days > 29) {
        if (days < 45) {
            returningItem = " around a month ago";
        } else if (days < 60) {
            returningItem = " one & half month ago";
        } else if (days < 75) {
            returningItem = " 2 months ago";
        } else if (days < 90) {
            returningItem = " around 3 months ago";
        } else if (days < 115) {
            returningItem = " 3 months ago";
        } else if (days < 135) {
            returningItem = " 4 months ago";
        } else if (days < 165) {
            returningItem = " 5 months ago";
        } else if (days < 195) {
            returningItem = " 6 months ago";
        } else if (days < 225) {
            returningItem = " 7 months ago";
        } else if (days < 255) {
            returningItem = " 8 months ago";
        } else if (days < 285) {
            returningItem = " 9 months ago";
        } else if (days < 315) {
            returningItem = " 10 months ago";
        } else if (days < 345) {
            returningItem = " 11 months ago";
        } else if (days < 361) {
            returningItem = " last year";
        }
    } else if (days > 360) {
        returningItem = " last year";
    } else if (days > 720) {
        returningItem = " 1 and half year ago";
    } else {
        returningItem = " 2 years ago";
    }

    return returningItem;
}