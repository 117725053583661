import React, { useContext } from 'react'
import { Routes, Route } from "react-router-dom";
import EachUserWrapper from '../Content/Users/EachUserWrapper';
import Login from '../Content/AuthPages/Login';
import BookingDetails from '../Content/Bookings/BookingDetails';
import CurrentSessions from '../Content/Bookings/CurrentSessions';
import FeedbackHome from '../Content/Feedback/FeedbackHome';
import AddNewMember from '../Content/Membership/AddNewMember';
import AllMembers from '../Content/Membership/AllMembers';
import CardDetails from '../Content/Membership/CardDetails';
import Home from '../Content/Pages/Home';
import ManageServices from '../Content/Services/ManageServices';
import ActiveSessions from '../Content/Sessions/ActiveSessions';
import Users from '../Content/Users/Users';
import AuthContext from '../store/auth-context';
import Ledger from '../Content/StoreLedger/Ledger';
import ManageStaff from '../Content/StaffManagement/ManageStaff';
import MonthlyRevenue from '../Content/Analytics/MonthlyRevenue';
import MusicPlayer from '../Content/Music/MusicPlayer';
import ConfigMembershipCards from '../Content/Membership/ConfigMembershipCards';

const MainNavigator = () => {
    const authCtx = useContext(AuthContext);

    if(!authCtx.isLoggedIn) {
        return(
            <Routes>
                <Route path="*" element={<Login />}></Route>    
                <Route path="/" element={<Login />}></Route>    
            </Routes>
        )
    } else {
        return (
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/services" element={<ManageServices />}></Route>
                <Route path="/users" element={<Users />}></Route>
                <Route path="/user/:clientIdentifier" element={<EachUserWrapper />}></Route>
                <Route path="/memberships" element={<AllMembers />}></Route>
                <Route path="/memberships/sell" element={<AddNewMember />}></Route>
                <Route path="/membership/:purchaseCardIdentifier" element={<CardDetails />}></Route>
                <Route path="/bookings" element={<CurrentSessions />}></Route>
                <Route path="/booking/:bookingIdentifier" element={<BookingDetails />}></Route>
                <Route path="/sessions" element={<ActiveSessions />}></Route>
                <Route path="/sessions/:sessionIdentifier" element={<ActiveSessions />}></Route>
                <Route path="/feedbacks" element={<FeedbackHome />}></Route>
                <Route path="/ledger" element={<Ledger />}></Route>
                <Route path="/staff" element={<ManageStaff />}></Route>
                <Route path="/analytics" element={<MonthlyRevenue />}></Route>
                
                <Route path="/config/membership-card/:selectedCard" element={<ConfigMembershipCards />}></Route>


                
                {/* <Route path="/music" element={<MusicPlayer />}></Route> */}
                {/* <Route component={MusicPlayer} ></Route> */}

            </Routes>
        )
    }

}

export default MainNavigator