import React, { useContext, useEffect, useState } from 'react'
import { BiCheckbox, BiCheckboxChecked } from 'react-icons/bi'
import { MdRefresh } from 'react-icons/md'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../Sessions/CollectPayment.module.css'
import { useNavigate } from 'react-router';

const CollectPayment = props => {
    let navigate = useNavigate()
    const authCtx = useContext(AuthContext)
    let isCollecting = props.isCollecting
    let abortCollection = props.abortCollection
    let netPayableAmount = props.netPayableAmount
    let sessionIdentifier = props.sessionIdentifier
    const clientStoreRelationIdentifier = props.clientStoreRelationIdentifier
    const flatDiscount = props.flatDiscount
    const flatDiscountPercentage = props.flatDiscountPercentage

    const [isUpdatingCollection, setIsUpdatingCollection] = useState('NOT_UPDATING')
    const [hasInvoiceShared, setHasInvoiceShared] = useState('NOT_SHARING') //NOT_SHARING, BEING_SHARED, SHARED
    const [isUpdatingStatus, setIsUpdatingStatus] = useState(false)

    const servicesTotalOrgPrice = props.totalServiceAmount
    const servicesTotalDiscountedPrice = props.totalServiceDiscountedAmount

    const [isLoadingLedgerBalance, setIsLoadingLedgerBalance] = useState(true)
    const [isLoadingSubscription, setIsLoadingSubscription] = useState(true)

    const [hasActionInitiated, setHasActionInitiated] = useState(false)


    // define all variables  
    const [ledgerBalance, setLedgerBalance] = useState(0)
    const [cardsArray, setCardsArray] = useState([
        // {
        //     cardIdentifier: 'sfh9kjhdfs',
        //     cardLabel: "Silver",
        //     isExpired: false,
        //     availableBalance: 4004,
        //     isUsing: false,
        //     balanceUsing: 0
        // },
        // {
        //     cardIdentifier: 'nakljsldf',
        //     cardLabel: "Gold",
        //     isExpired: false,
        //     availableBalance: 4000,
        //     isUsing: false,
        //     balanceUsing: 0
        // }
    ])
    const [collectionViaCard, setCollectionViaCard] = useState(0)
    const [collectionViaCash, setCollectionViaCash] = useState(0)
    const [collectionViaQr, setCollectionViaQr] = useState(0)
    const [remainingAmount, setRemainingAmount] = useState(netPayableAmount)

    const remainingAmountCalculator = () => {
        let raTemp = 0;
        let allCardsBalAvailTemp = 0;
        cardsArray.map((item) => {
            if (item.isUsing) {
                allCardsBalAvailTemp = parseFloat(allCardsBalAvailTemp) + parseFloat(item.balanceUsing)
            }
        })

        raTemp = netPayableAmount - (parseFloat(allCardsBalAvailTemp) + parseFloat(ledgerBalance) + parseFloat(collectionViaCard !== '' ? collectionViaCard : 0) + parseFloat(collectionViaCash !== '' ? collectionViaCash : 0) + parseFloat(collectionViaQr !== '' ? collectionViaQr : 0))
        setRemainingAmount(parseFloat(raTemp).toFixed(1))
    }

    const cardBalanceUseToggle = (cardId) => {
        let requestedBalanceTemp = 0;
        const index = cardsArray.findIndex(card => card.cardIdentifier === cardId);

        if (cardsArray[index].isUsing) {
            requestedBalanceTemp = 0
        } else {
            if (remainingAmount > cardsArray[index].availableBalance) {
                requestedBalanceTemp = cardsArray[index].availableBalance
            } else {
                requestedBalanceTemp = remainingAmount
            }
        }

        const updatedCard = { ...cardsArray[index], isUsing: !cardsArray[index].isUsing, balanceUsing: requestedBalanceTemp };
        const updatedCardsArray = [...cardsArray];
        updatedCardsArray[index] = updatedCard;
        setCardsArray(updatedCardsArray);
    };


    const getLedgerBalance = async () => {
        setIsLoadingLedgerBalance(true)
        const getLedgerBalanceResponse = await fetch(BASE_URL_API + "getLedgerBalance",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier.clientStoreRelationIdentifier
                })
            });

        if (!getLedgerBalanceResponse.ok) {
            console.log("Something went wrong : Server Error")

        } else {
            const getLedgerBalanceRespo = await getLedgerBalanceResponse.json()
            if (getLedgerBalanceRespo.status === 'success') {
                setLedgerBalance(getLedgerBalanceRespo.response["currentBalance"])
            } else {
                if (getLedgerBalanceRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    console.log(getLedgerBalanceRespo)
                }
            }
        }
        setIsLoadingLedgerBalance(false)
    }


    const getCardsBalanceHandler = async () => {
        setIsLoadingSubscription(true)
        const getCardsBalanceResponse = await fetch(BASE_URL_API + "getCardsForPaymentCollection",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier.clientStoreRelationIdentifier
                })
            });

        if (!getCardsBalanceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getCardsBalanceRespo = await getCardsBalanceResponse.json();
            if (getCardsBalanceRespo.status === 'success') {
                setCardsArray(getCardsBalanceRespo.response)
            } else {
                setCardsArray([])
            }
            console.log(getCardsBalanceRespo)
        }
        setIsLoadingSubscription(false)
    }

    const initiateCollectionUpdate = () => {
        setHasActionInitiated(true)
    }

    const updateCollectionHandler = async () => {
        setIsUpdatingCollection('UPDATING')
        let requestedAmountFromCards = [];
        cardsArray.map((item) => {
            if (item.isUsing === true) {
                requestedAmountFromCards.push(item)
            }
        })
        // console.log(requestedAmountFromCards);   
        const updateCollectionResponse = await fetch(BASE_URL_API + "updateCollection",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier,
                    bookingIdentifier: sessionIdentifier,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier.clientStoreRelationIdentifier,
                    ledgerBalanceRequested: ledgerBalance,
                    cardsArray: requestedAmountFromCards,
                    amountToBeCollected: netPayableAmount,
                    collectionViaCash: collectionViaCash,
                    collectionViaCard: collectionViaCard,
                    collectionViaQR: collectionViaQr,
                    remainingAmount: remainingAmount,

                    flatDiscountByAmount: flatDiscount,
                    flatDiscountByPercentage: flatDiscountPercentage,

                    servicesTotalOrgPrice: servicesTotalOrgPrice,
                    servicesTotalDiscountedPrice: servicesTotalDiscountedPrice

                })
            });

        if (!updateCollectionResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const updateCollectionRespo = await updateCollectionResponse.json();
            if (updateCollectionRespo.status === 'success') {
                setIsUpdatingCollection('UPDATED')

            } else {
                if (updateCollectionRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else if (updateCollectionRespo.message === 'invalid request from ledger') {
                    getLedgerBalance()
                    setIsUpdatingCollection('NOT_UPDATING')
                }
            }
            console.log(updateCollectionRespo)
        }
    }

    const sendInvoiceHandler = async () => {
        setHasInvoiceShared('BEING_SHARED')
        const sendInvoiceResponse = await fetch(BASE_URL_API+"sendInvoice",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                bookingIdentifier : sessionIdentifier
            })
        });

        if(!sendInvoiceResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const sendInvoiceRespo = await sendInvoiceResponse.json();
            if(sendInvoiceRespo.status==='success') {
                setHasInvoiceShared('SHARED')
            } else {
                if(sendInvoiceRespo.message ==='tokenExpired') {
                    authCtx.logout()
                } else {
                    setHasInvoiceShared('NOT_SHARING')
                    console.log(sendInvoiceRespo)
                }
            }
        }

    }


    const markAsCompletedHandler = async () => {
        setIsUpdatingStatus(true)
        const updateSessionStatusResponse = await fetch(BASE_URL_API + "updateSessionStatus",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    bookingIdentifier: sessionIdentifier,
                    statusToBeUpdated: 'BOOKING_COMPLETED'
                })
            });

        if (!updateSessionStatusResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateSessionStatusRespo = await updateSessionStatusResponse.json();
            if (updateSessionStatusRespo.status == "success") {
                navigate('/sessions')
            } else {
                if (updateSessionStatusRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(updateSessionStatusRespo);
        }
        setIsUpdatingStatus(false)
    }

    useEffect(() => {
        if (clientStoreRelationIdentifier !== '') {
            getLedgerBalance().then(
                getCardsBalanceHandler()
            )
        }
    }, [clientStoreRelationIdentifier])

    useEffect(() => {
        if (clientStoreRelationIdentifier !== '') {
            getCardsBalanceHandler()
        }
    }, [netPayableAmount])


    useEffect(() => {
        remainingAmountCalculator()
    }, [netPayableAmount, ledgerBalance, cardsArray, collectionViaCard, collectionViaCash, collectionViaQr])

    const toggleIsUsing = (k) => {
        cardBalanceUseToggle(k)
    }

    // console.log(cardsArray)

    if (isCollecting === false) {
        return null
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    style={{ animation: `.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideLeftToRight` }}
                >
                    <div className={styles.collectionWrapper}>
                        <div className={styles.toBeCollectedWrapper}>
                            <div className={styles.toBeCollected}>
                                &#8377;{netPayableAmount}/-
                            </div>
                            <div className={styles.toBeCollectedCaption}>
                                To Be Collected
                            </div>
                        </div>

                        <div className={styles.collectionWrapperBody}>
                            <div className={styles.ledgerWrapper}>
                                <div className={styles.ledgerHeader}>
                                    Wallet Balance
                                </div>
                                {isLoadingLedgerBalance === true ?
                                    <div>
                                        Hold on,
                                        fetching old balance from ledger..
                                    </div>
                                    :
                                    <div className={styles.ledgerContainer}>
                                        <div>
                                            Ledger
                                        </div>
                                        <div>
                                            {ledgerBalance < 0 ? <span>-&#8377;{ledgerBalance * -1}</span> : <span>&#8377;{ledgerBalance}</span>}/-
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={styles.subscriptionsWrapper}>
                                <div className={styles.subscriptionsHeader}>
                                    <div>
                                        Subscription Cards
                                    </div>
                                    <div>
                                        <button className={styles.refreshBtn}
                                            onClick={() => getCardsBalanceHandler()}
                                        >
                                            <MdRefresh size={20} color="#c1c1c1" />
                                        </button>
                                    </div>
                                </div>
                                {isLoadingSubscription === true ?
                                    <div>
                                        Looking for any subscriptions purchased...
                                    </div>
                                    :
                                    <div>
                                        {cardsArray.map((item, index) => (
                                            <div key={index} className={styles.eachCardWrapper} >
                                                <div className={styles.colLeft}>
                                                    <div className={styles.colLeftFirst}>
                                                        <div className={styles.cardLabel}>
                                                            {item.cardName}
                                                        </div>
                                                    </div>
                                                    <div className={styles.colLeftSecond}>
                                                        <div className={styles.cardNumber}>
                                                            {item.cardIdentifier.substring(0, 4)}-{item.cardIdentifier.substring(4, 8)}-{item.cardIdentifier.substring(8, 12)}-{item.cardIdentifier.substring(12, 16)}
                                                        </div>
                                                        <div className={styles.expiryDate}>
                                                            Expires on 12/23/2024
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={styles.colCenter}>
                                                    {item.availableBalance}
                                                    <div>
                                                        {item.balanceUsing}
                                                    </div>
                                                </div>
                                                <div className={styles.colRight}>
                                                    {item.availableBalance > 0 ?
                                                        <div className={styles.checkBoxBtn}>
                                                            {item.isUsing === false ?
                                                                remainingAmount > 0 &&
                                                                <button
                                                                    onClick={() => toggleIsUsing(item.cardIdentifier)}
                                                                    className={styles.checkBoxUnchecked}
                                                                >
                                                                    <BiCheckbox size={34} />
                                                                </button>
                                                                :
                                                                <button
                                                                    onClick={() => toggleIsUsing(item.cardIdentifier)}
                                                                >
                                                                    <BiCheckboxChecked size={34} />
                                                                </button>
                                                            }
                                                        </div>
                                                        :
                                                        <div className={styles.checkBoxBtn}>
                                                            <button
                                                                disabled={true}
                                                                title="Balance is not available!"
                                                            >
                                                                <BiCheckbox size={34} />
                                                            </button>
                                                        </div>
                                                    }
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                }

                            </div>
                            <div className={styles.currentPayWrapper}>

                                <div className={styles.eachCurrentPay}>
                                    <div>
                                        Cash Collection
                                    </div>
                                    <div className={styles.amountInputField}>
                                        <input onChange={(e) => setCollectionViaCash(e.target.value)}
                                            value={collectionViaCash}
                                            onFocus={(e) => e.target.select()}
                                            autoFocus={true}
                                        />
                                    </div>
                                </div>


                                <div className={styles.eachCurrentPay}>
                                    <div>
                                        Collected via QR
                                    </div>
                                    <div className={styles.amountInputField}>
                                        <input onChange={(e) => setCollectionViaQr(e.target.value)}
                                            value={collectionViaQr}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    </div>
                                </div>


                                <div className={styles.eachCurrentPay}>
                                    <div>
                                        Collected via Card
                                    </div>
                                    <div className={styles.amountInputField}>
                                        <input onChange={(e) => setCollectionViaCard(e.target.value)}
                                            value={collectionViaCard}
                                            onFocus={(e) => e.target.select()}
                                        />
                                    </div>
                                </div>


                                <div className={styles.remainingWrapper}>
                                    <div className={styles.rwLeft}>
                                        <div>
                                            Remaining Amount
                                        </div>
                                        <div>
                                            {remainingAmount < 0 ?
                                                <span className={styles.rAmtTxtExtra}>
                                                    Customer is paying &#8377;{remainingAmount * -1} extra, amount can be used for the next visit.
                                                </span>
                                                : remainingAmount > 0 ?
                                                    <span className={styles.rAmtTxtShort}>
                                                        Customer needs to pay &#8377;{remainingAmount} to clear the balance.
                                                    </span>
                                                    :
                                                    <span></span>
                                            }
                                        </div>
                                    </div>
                                    <div>
                                        {remainingAmount < 0 ? <span>-&#8377;{remainingAmount * -1}</span> : <span>&#8377;{remainingAmount}</span>}/-
                                    </div>
                                </div>


                            </div>
                            <div className={styles.updateActionsWrapper}>
                                {hasActionInitiated === false ?
                                    <div className={styles.uaInitiatorWrapper}>
                                        <button onClick={() => abortCollection()} className={styles.cancelUpdateCollectionInit}>Cancel</button>
                                        <button onClick={() => initiateCollectionUpdate()} className={styles.updateCollectionInit} >Update Collection</button>
                                    </div>
                                    :
                                    <div className={styles.uaConfirmationWrapper}>
                                        {isUpdatingCollection === 'NOT_UPDATING' &&
                                            <div className={styles.confirmationBtns}>
                                                <div>
                                                    <div className={styles.summaryWrapper}>
                                                        <div className={styles.summaryTitle}>
                                                            Collection Summary
                                                        </div>
                                                        <div className={styles.summaryRows}>
                                                            <ul>
                                                                {remainingAmount > 0 ?
                                                                    <li>
                                                                        <span>Customer is paying &#8377;{remainingAmount} lesser.</span>
                                                                    </li>
                                                                    : remainingAmount < 0 ?
                                                                        <li>
                                                                            <span>Customer is paying &#8377;{remainingAmount * -1} extra.</span>
                                                                        </li>
                                                                        :
                                                                        <span></span>
                                                                }

                                                                {cardsArray.map((item, index) => (
                                                                    item.isUsing === true ?
                                                                        <li key={index}>
                                                                            Using ₹{item.balanceUsing} of {item.cardIdentifier} card
                                                                        </li>
                                                                        : ""
                                                                ))
                                                                }

                                                                {collectionViaCash !== 0 && collectionViaCash !== '' &&
                                                                    <li>
                                                                        Client is paying &#8377;{collectionViaCash} in cash.
                                                                    </li>
                                                                }
                                                                {collectionViaCard !== 0 && collectionViaCard !== '' &&
                                                                    <li>
                                                                        Client is paying &#8377;{collectionViaCard} by card.
                                                                    </li>
                                                                }
                                                                {collectionViaQr !== 0 && collectionViaQr !== '' &&
                                                                    <li>
                                                                        Client is paying &#8377;{collectionViaQr} by QR.
                                                                    </li>
                                                                }
                                                            </ul>
                                                        </div>
                                                    </div>
                                                    <div className={styles.areYouSureTxt}>
                                                        Are you sure you want to update the collection?
                                                    </div>
                                                </div>
                                                <div className={styles.confirmBtnContainer}>
                                                    <button onClick={() => setHasActionInitiated(false)}
                                                        className={styles.actionBtnDeny}
                                                    >
                                                        No
                                                    </button>
                                                    <button onClick={() => updateCollectionHandler()}
                                                        className={styles.actionBtnYes}
                                                    >
                                                        Yes
                                                    </button>
                                                </div>
                                            </div>
                                        }
                                        {isUpdatingCollection === 'UPDATING' &&
                                            <div className={styles.confirmationBtns}>
                                                <div className={styles.summaryWrapper}>
                                                    <div className={styles.summaryTitle}>
                                                        Please wait..
                                                    </div>
                                                </div>
                                                <div className={styles.loaderContainer}>
                                                    <div className={styles.loaderIcon}>
                                                        <img src="https://res.cloudinary.com/iamironman/image/upload/v1630782519/web-icons/Ajax-loader_w2minh.gif" />
                                                    </div>
                                                    <div>
                                                        Updating Collection...
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {isUpdatingCollection === 'UPDATED' &&
                                            <div className={styles.confirmationBtns}>
                                                <div className={styles.collectionDoneContainer}>
                                                    <div className={styles.summaryTitle}>
                                                        Payment Updation Successful!
                                                    </div>
                                                    <div className={styles.collectionDoneIcon}>
                                                        <img src="https://res.cloudinary.com/iamironman/image/upload/v1628707322/web-icons/success-green-check-mark_wsoqy8.png" />
                                                    </div>
                                                </div>

                                                <div className={styles.sendInvoiceWrapper}>
                                                    {hasInvoiceShared === 'NOT_SHARING' &&
                                                        <button className={styles.sendInvoiceBtn}
                                                            onClick={() => sendInvoiceHandler()}
                                                        >
                                                            Send the Bill
                                                        </button>
                                                    }
                                                    {hasInvoiceShared === 'BEING_SHARED' &&
                                                        <button disabled className={styles.invoiceBeingShared}>
                                                            Sending Bill...
                                                        </button>
                                                    }
                                                    {hasInvoiceShared === 'SHARED' &&
                                                        <div className={styles.invoiceSharedTxt}>
                                                            Invoice successfully shared!
                                                        </div>
                                                    }
                                                </div>

                                                {hasInvoiceShared === 'SHARED' &&
                                                    <div className={styles.markAsCompletedWrapper}>
                                                        {isUpdatingStatus === false ?
                                                            <button onClick={() => markAsCompletedHandler()}>
                                                                Mark as completed
                                                            </button>
                                                            :
                                                            <div className={styles.updatingStatusTxt}>
                                                                Updating Status...
                                                            </div>
                                                        }
                                                    </div>
                                                }

                                                <div className={styles.poweredBy}>
                                                    powered By Lokaci
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default CollectPayment