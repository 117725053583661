import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Users/AllLedgerTransactionsWrapper.module.css'

const AllLedgerTransactionsWrapper = props => {
    const authCtx = useContext(AuthContext);
    const clientStoreRelationIdentifier = props.clientStoreRelationIdentifier;

    const [allTransactions, setAllTransactions] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const getAllLedgerTransactions = async () => {
        setIsLoading(true)
        const getAllLedgerTransactionsResponse = await fetch(BASE_URL_API + "getAllLedgerTransactions",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!getAllLedgerTransactionsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getAllLedgerTransactionsRespo = await getAllLedgerTransactionsResponse.json();
            if (getAllLedgerTransactionsRespo.status === 'success') {
                setAllTransactions(getAllLedgerTransactionsRespo.response)
            } else {
                if (getAllLedgerTransactionsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {
                    setAllTransactions([])
                    console.log(getAllLedgerTransactionsRespo)
                }
            }
        }
        setIsLoading(false)
    }

    useEffect(() => {
        if (clientStoreRelationIdentifier !== '') {
            getAllLedgerTransactions()
        }
    }, [clientStoreRelationIdentifier])


    return (
        <div>
            <div>
                {isLoading === true ?
                    <div>
                        Collecting transactions...
                    </div>
                    :
                    <div>
                        {allTransactions.length} transactions available
                    </div>
                }
            </div>
            <div>
                {allTransactions.map((item, index) => (
                    <div key={index} className={styles.eachRowWrapper}>
                        <div>
                            {item.transactionDate}
                        </div>
                        <div>
                            {item.amount}
                        </div>
                        <div>
                            {item.transactionType}
                        </div>
                        <div>
                            {item.currentBalance}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default AllLedgerTransactionsWrapper