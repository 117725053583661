import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachServicesCategory.module.css';
import AddNewService from './AddNewService';
import EachAddedService from './EachAddedService';

const EachServicesCategory = props => {
    const authCtx = useContext(AuthContext);
    let item = props.item;
    // console.log(item)
    let selectServiceHandler = props.selectServiceHandler;
    let storeIdentifier = '201655614158001803';
    let categoryIdentifier = item.categoryIdentifier
    const [isExpanded, setIsExpanded] = useState(false)
    const [addedServices, setAddedServices] = useState(null);

    

    const getServices = async () => {
        const getServicesResponse = await fetch(BASE_URL_API + "getServices",
        {
            method : "POST",
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : storeIdentifier, 
                categoryIdentifier : categoryIdentifier
            })
        });

        if(!getServicesResponse.ok) {
            console.log("Something went wrong : Server Error!");
        } else {
            const getServicesRespo = await getServicesResponse.json();
            if(getServicesRespo.status=="success") {
                setAddedServices(getServicesRespo.response)
            } else {
                setAddedServices(null)
                // console.log(getServicesRespo.response);
            }
        }
    }

    useEffect(() => {
        getServices();
    }, [])
    
    // console.log("This is added services : ", addedServices);

    return (
        <div className={styles.mainContainer}>
            <div className={styles.categoryHeader} onClick={() => setIsExpanded(!isExpanded)}>
                <div className={styles.categoryTitle}>{item.categoryName}</div>
                <div >Show</div>
            </div>
            {isExpanded &&
                <div className={styles.categoryBody}>
                    {addedServices != null ?
                        <div className={styles.addedServicesBody}>
                            {addedServices.map((itemAdded, index) => (
                                <EachAddedService
                                    key={index}
                                    item={itemAdded}
                                    selectServiceHandler={selectServiceHandler}
                                />
                            ))}
                        </div>
                        :
                        <div className={styles.addedServicesBody}>
                            No services added for this category!
                        </div>
                    }
                    <div>
                        <AddNewService 
                            storeIdentifier={storeIdentifier}
                            categoryIdentifier={categoryIdentifier}
                            categoryName={item.categoryName}
                            genderIdentifier={item.genderIdentifier}
                            getServices={getServices}
                        />
                    </div>
                </div>
            }
        </div>
    )
}

export default EachServicesCategory