import React, { useContext, useEffect, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../../Styles/Components/Services/EachAddedService.module.css'

const EachAddedService = props => {
    const authCtx = useContext(AuthContext);
    let item = props.item;
    let selectServiceHandler = props.selectServiceHandler;
    const [showDiscountForm, setShowDiscountForm] = useState(false);
    const [discountPercentage, setDiscountPercentage] = useState(item.offerPercentage);
    const [amountToBeDiscounted, setAmountToBeDiscounted] = useState(0);
    const [discountedBasePrice, setDiscountedBasePrice] = useState(0);

    const addDiscountInitiate = () => {
        setShowDiscountForm(true)
    }

    const addDiscountPercentageHandler = (k) => {
        if (k < 0) {
            k = 0;
        } else if (k > 100) {
            k = 100;
        }
        if (k == '') {
            k = 0;
            let atbd = parseFloat(item.basePrice) * parseFloat(k) / 100;
            setAmountToBeDiscounted(parseFloat(atbd).toFixed(2))
            setDiscountedBasePrice(parseFloat(item.basePrice - atbd).toFixed(2))
        } else {
            let atbd = parseFloat(item.basePrice) * parseFloat(k) / 100;
            setAmountToBeDiscounted(parseFloat(atbd).toFixed(2))
            setDiscountedBasePrice(parseFloat(item.basePrice - atbd).toFixed(2))
        }
        setDiscountPercentage(k);
    }

    useEffect(() => {
        addDiscountPercentageHandler(discountPercentage)
    }, [])
    

    const addDiscountHandler = async () => {
        const updateDiscountOfferResponse = await fetch(BASE_URL_API + "updateDiscountOffer",
            {
                method: "POST",
                body: JSON.stringify({
                    token : authCtx.token,
                    identifier: item.identifier,
                    offerPercentage: discountPercentage
                })
            });

        if (!updateDiscountOfferResponse.ok) {
            console.log("Something went wrong : Server Error");
        } else {
            const updateDiscountOfferRespo = await updateDiscountOfferResponse.json();
            if (updateDiscountOfferRespo.status == "success") {
                setShowDiscountForm(false);
                addDiscountPercentageHandler(discountPercentage)
            } else {
                console.log(updateDiscountOfferRespo.message)
            }
        }
    }

    if (item == null) {
        return (
            <div>Null</div>
        )
    }
    return (
        <div className={styles.mainContainer}
            onClick={() => selectServiceHandler(item)}
        >
            <div>
                <div className={styles.topContainer}>
                    <div>
                        {item.serviceName}
                    </div>
                    <div>
                        ₹{item.basePrice}
                    </div>
                </div>
                <div className={styles.discountedContainer}>
                    <div>
                        Discounted Price ({discountPercentage}%) [-₹{amountToBeDiscounted}]
                    </div>
                    <div>
                        ₹{discountedBasePrice}
                    </div>
                </div>
                <div>
                    <div className={styles.actionContainer}>
                        <div>
                            {showDiscountForm == false ?
                                <div>
                                    <button className={styles.eachActionBtn}
                                        onClick={() => addDiscountInitiate()}
                                    >
                                        Add Discount
                                    </button>
                                </div>
                                :
                                <div>
                                    <input
                                        placeholder="Add discount %"
                                        onChange={(e) => addDiscountPercentageHandler(e.target.value)}
                                        value={discountPercentage}
                                        type="number"
                                    />
                                    <button onClick={() => addDiscountHandler()} >Update</button>
                                    <button onClick={() => setShowDiscountForm(false)}>Cancel</button>
                                </div>
                            }
                        </div>
                        {/* <div>Add Add-Ons</div> */}
                        <div className={styles.eachActionBtn}>Edit Service</div>
                        <div className={styles.eachActionBtn}>Delete Service</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EachAddedService