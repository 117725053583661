import React, { useState } from "react";

const AuthContext = React.createContext({
    token: localStorage.getItem("token"),
    isLoggedIn: false,
    storeIdentifier : localStorage.getItem("storeIdentifier"),
    login: (token) => { },
    logout: () => { }
});

export const AuthContextProvider = (props) => {
    const [token, setToken] = useState(localStorage.getItem('token'));
    const [storeIdentifier, setStoreIdentifier] = useState(localStorage.getItem('storeIdentifier'));
    const userIsLoggedIn = !!token;

    const loginHandler = (token, storeIdentifier) => {
        localStorage.setItem("token", token);
        localStorage.setItem("storeIdentifier", storeIdentifier);
        setToken(token);
        setStoreIdentifier(storeIdentifier);
    }

    const logoutHandler = () => {
        setToken(null);
        setStoreIdentifier(null);
        localStorage.removeItem("token");
        localStorage.removeItem("storeIdentifier");
    }
    const contextValue = {
        token: token,
        isLoggedIn: userIsLoggedIn,
        storeIdentifier : storeIdentifier,
        login: loginHandler,
        logout: logoutHandler
    }

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>
}

export default AuthContext;