import React, { useContext, useEffect, useState } from 'react'
import OtpInput from 'react-otp-input';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Users/AddNewClient.module.css'

const AddNewClient = props => {
    const authCtx = useContext(AuthContext);
    const isOpen = props.isOpen;
    const closeModal = props.closeModal;
    const assignClient = props.assignClient;

    const numbers = Array.from({ length: 30 }, (_, i) => i + 1);
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    const [isReadyToSubmit, setIsReadyToSubmit] = useState(false);
    const [creationStatus, setCreationStatus] = useState('NOT_UPDATING'); // UPDATING

    window.onclick = function (event) {
        if (event.target.id == "addNewClientForm") {
            closeModal();
        }

        if (event.target.id == "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }

    }

    document.addEventListener('keydown', function (e) {
        if (e.keyCode == 27) {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
            closeModal();
        }
    });

    const [phoneNumber, setPhoneNumber] = useState({
        otp: props.phone
    });
    const [firstName, setFirstName] = useState('');
    const [middleName, setMiddleName] = useState('')
    const [lastName, setLastName] = useState('')
    const [gender, setGender] = useState('')
    const [address, setAddress] = useState('')
    const [dayAnniversary, setDayAnniversary] = useState('')
    const [monthAnniversary, setMonthAnniversary] = useState('')

    const phoneNumberHandler = (otp) => {
        setPhoneNumber({ otp });
    }

    const isReadyHandler = () => {
        if (phoneNumber.otp.length === 10) {
            if (firstName.length > 1) {
                if (gender !== '') {
                    setIsReadyToSubmit(true)
                } else {
                    setIsReadyToSubmit(false)
                }
            } else {
                setIsReadyToSubmit(false)
            }
        } else {
            setIsReadyToSubmit(false)
        }
    }

    useEffect(() => {
        isReadyHandler()
    }, [phoneNumber, firstName, gender])

    useEffect(() => {
        setPhoneNumber({
            otp: props.phone
        })
    }, [props.phone])

    const addNewClientHandler = async () => {
        setCreationStatus('UPDATING')

        console.log(phoneNumber.otp, firstName, middleName, lastName, gender, address, dayAnniversary, monthAnniversary)
        
        const addNewClientResponse = await fetch(BASE_URL_API+"addNewClient",
        {
            method : 'POST',
            body : JSON.stringify({
                token : authCtx.token,
                storeIdentifier : authCtx.storeIdentifier,
                mobile : phoneNumber.otp,
                firstName : firstName,
                middleName : middleName,
                lastName : lastName,
                gender : gender,
                address : address,
                anniversary : dayAnniversary+"/"+monthAnniversary+"XXXX"
            })
        });

        if(!addNewClientResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const addNewClientRespo = await addNewClientResponse.json();
            if(addNewClientRespo.status==='success') {
                if(assignClient!==undefined) {
                    assignClient(addNewClientRespo.response)
                    closeModal()
                }
                toast.success("Client successfully onboarded!")
            } else {
                if(addNewClientRespo.message==='tokenExpired') {
                    authCtx.logout()
                } else if(addNewClientRespo.message==='user already registered') {
                    if(assignClient!==undefined) {
                        assignClient(addNewClientRespo.response)
                        closeModal()
                    }
                }
            }

            console.log(addNewClientRespo);
        }

        setCreationStatus('NOT_UPDATING')

    }

    



    if (isOpen) {
        return (
            <div className={styles.mainWrapper}>
                <ToastContainer />
                <div className={styles.mainContainer}>
                    <div className={styles.filtersPanelWrapper}
                        id="addNewClientForm"
                        style={{
                            animation: `openAddNewClientPanel 0.2s forwards`
                        }}
                    >
                        <div className={styles.mainLeft}>
                            <div className={styles.bodyContent}>
                                <div className={styles.bodyTitle}>
                                    Add A New Client
                                </div>
                                {creationStatus === 'NOT_UPDATING' &&
                                    <div className={styles.formBody}>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Enter 10 digit Phone number.
                                            </div>
                                            <div >
                                                <OtpInput
                                                    value={phoneNumber.otp}
                                                    numInputs={10}
                                                    shouldAutoFocus={true}
                                                    onChange={(otp) => phoneNumberHandler(otp)}
                                                    isInputNum={true}
                                                    containerStyle={styles.phoneNumberContainer}
                                                    inputStyle={styles.phoneInputBox}
                                                />
                                            </div>
                                        </div>

                                        <div className={styles.nameWrapper}>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    First Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setFirstName(e.target.value)} value={firstName} />
                                                </div>
                                            </div>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    Middle Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setMiddleName(e.target.value)} value={middleName} />
                                                </div>
                                            </div>
                                            <div className={styles.eachNamePart}>
                                                <div className={styles.eachNamePartTitle}>
                                                    Last Name
                                                </div>
                                                <div className={styles.eachNamePartInputWrapper}>
                                                    <input onChange={(e) => setLastName(e.target.value)} value={lastName} />
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.genderWrapper}>
                                            <div className={styles.genderTitle}>
                                                Gender
                                            </div>
                                            <div className={styles.genderBtnWrapper}>
                                                <button onClick={() => setGender('Male')} className={gender === 'Male' ? styles.genderBtnSelected : styles.genderBtnDefault}>Male</button>
                                                <button onClick={() => setGender('Female')} className={gender === 'Female' ? styles.genderBtnSelected : styles.genderBtnDefault}>Female</button>
                                                <button onClick={() => setGender('LGBTQ')} className={gender === 'LGBTQ' ? styles.genderBtnSelected : styles.genderBtnDefault}>LGBTQ</button>
                                                <button onClick={() => setGender('Unknown')} className={gender === 'Unknown' ? styles.genderBtnSelected : styles.genderBtnDefault}>Unknown</button>
                                            </div>
                                        </div>

                                        <div className={styles.addressWrapper}>
                                            <div className={styles.genderTitle}>
                                                Address
                                            </div>
                                            <div className={styles.addressBoxWrapper}>
                                                <textarea onChange={(e) => setAddress(e.target.value)}
                                                    value={address}
                                                >

                                                </textarea>
                                            </div>
                                        </div>

                                        <div className={styles.birthAnniversaryWrapper}>
                                            <div className={styles.genderTitle}>
                                                Birth Anniversary
                                            </div>
                                            <div className={styles.anniversaryOptions}>
                                                <div className={styles.eachOptionWrapper}>
                                                    <div className={styles.anniversaryOptionTitle}>
                                                        Day
                                                    </div>
                                                    <div className={styles.anniversaryOption}>
                                                        <select onChange={(e) => setDayAnniversary(e.target.value)}>
                                                            {numbers.map((item, index) => (
                                                                <option key={index}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                                <div className={styles.eachOptionWrapper}>
                                                    <div className={styles.anniversaryOptionTitle}>
                                                        Month
                                                    </div>
                                                    <div className={styles.anniversaryOption}>
                                                        <select onChange={(e) => setMonthAnniversary(e.target.value)}>
                                                            {months.map((item, index) => (
                                                                <option key={index}>{item}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                </div>

                                            </div>


                                        </div>

                                        <div className={styles.eachOptionWrapper}>
                                            {isReadyToSubmit === true ?
                                                <button className={styles.addNowBtn}
                                                    onClick={() => addNewClientHandler()}
                                                >Add Now</button>
                                                :
                                                <button className={styles.addNowBtnDull}>Add Now</button>
                                            }
                                        </div>
                                    </div>
                                }

                                {creationStatus === 'UPDATING' &&
                                    <div className={styles.formBody}>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Hold On!
                                            </div>
                                            <div >

                                            </div>
                                        </div>
                                        <div className={styles.phoneNumberWrapper}>
                                            <div className={styles.phoneTitle}>
                                                Please wait for a moment,
                                            </div>
                                            <div >

                                            </div>
                                        </div>
                                        <div>
                                            Adding now ...
                                        </div>
                                        <div className={styles.eachOptionWrapper}>
                                            <button className={styles.addNowBtn}>
                                                Adding...
                                            </button>
                                        </div>
                                    </div>
                                }

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            null
        )
    }
}

export default AddNewClient