import React, { useContext, useEffect, useState } from 'react'
import NavAtHome from '../../Components/Navigators/NavAtHome'
import styles from '../Users/EachUserWrapper.module.css'
import { useParams } from 'react-router';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { MdCalendarToday, MdFilter, MdFilterAlt, MdPhoneInTalk, MdRefresh, MdSort } from 'react-icons/md';
import AllVisitsWrapper from '../../Components/Users/AllVisitsWrapper';
import AllLedgerTransactionsWrapper from '../../Components/Users/AllLedgerTransactionsWrapper';
import AllPurchaseCardsAtClientPage from '../../Components/Users/AllPurchaseCardsAtClientPage';
import { BsPhone, BsTelephone } from 'react-icons/bs';
import { IoCardSharp } from "react-icons/io5";
import { IoMdSettings } from "react-icons/io";
import { MdAccountBalanceWallet } from "react-icons/md";
import AccountSettings from '../../Components/Users/AccountSettings';



const EachUserWrapper = props => {
    let params = useParams();
    const authCtx = useContext(AuthContext)
    const clientIdentifier = params.clientIdentifier;
    const [clientDetails, setClientDetails] = useState(null)
    const [isFetchingClientDetails, setIsFetchingClientDetails] = useState(false)
    const [activeTab, setActiveTab] = useState('CARDS') // LEDGER 

    const getClientFullDetails = async () => {
        setIsFetchingClientDetails(true)
        const getClientFullDetailsResponse = await fetch(BASE_URL_API + "getClientFullDetails",
            {
                method: 'POST',
                body: JSON.stringify({
                    token: authCtx.token,
                    storeIdentifier: authCtx.storeIdentifier,
                    clientStoreRelationIdentifier: clientIdentifier
                })
            });

        if (!getClientFullDetailsResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const getClientFullDetailsRespo = await getClientFullDetailsResponse.json();
            if (getClientFullDetailsRespo.status === 'success') {
                setClientDetails(getClientFullDetailsRespo.response[0])
            } else {
                if (getClientFullDetailsRespo.message === 'tokenExpired') {
                    authCtx.logout()
                } else {

                }
            }
            console.log(getClientFullDetailsRespo)
        }
        setIsFetchingClientDetails(false)
    }

    useEffect(() => {
        getClientFullDetails()
    }, [clientIdentifier])


    if (clientDetails === null) {
        return (
            <div>
                No records available
            </div>
        )
    }



    return (
        <div className={styles.mainWrapper}>
            <NavAtHome />
            <div className={styles.mainContainer}>
                <div className={styles.leftColWrapper}>
                    <div className={styles.leftColContainer}>
                        <div className={styles.eachIcon}
                            onClick={() => setActiveTab("CARDS")}
                        >
                            <MdAccountBalanceWallet size={24} />
                        </div>
                        <div className={styles.eachIcon}
                            onClick={() => setActiveTab("LEDGER")}
                        >
                            <IoCardSharp size={24} />
                        </div>
                        <div className={styles.eachIcon}
                            onClick={() => setActiveTab("SETTINGS")}
                        >
                            <IoMdSettings size={24} />
                        </div>
                    </div>
                </div>
                <div className={styles.leftWrapper}>
                    <div className={styles.leftHeader}>

                    </div>
                    <div className={styles.leftProfileSynop}>
                        <div className={styles.clientName}>
                            {clientDetails?.client_name.toLowerCase()}
                        </div>
                        <div className={styles.gender}>
                            {clientDetails?.gender}
                        </div>
                        <div className={styles.mobile}>
                            <MdPhoneInTalk size={12} />&nbsp;{clientDetails?.mobile}
                        </div>
                    </div>
                    <div className={styles.subMenusWrapper}>
                        <div className={styles.tabsWrapper}>
                            <button onClick={() => setActiveTab("CARDS")} className={activeTab==="CARDS" ? styles.activeTab : styles.eachTab} >Cards</button>
                            <button onClick={() => setActiveTab("LEDGER")} className={activeTab==="LEDGER" ? styles.activeTab : styles.eachTab} >Ledger</button>
                            <button onClick={() => setActiveTab("SETTINGS")} className={activeTab==="SETTINGS" ? styles.activeTab : styles.eachTab} >Settings</button>
                        </div>
                        <div>
                            {activeTab === "LEDGER" && <AllLedgerTransactionsWrapper clientStoreRelationIdentifier={clientIdentifier}/>}
                            {activeTab === "CARDS" && <AllPurchaseCardsAtClientPage clientStoreRelationIdentifier={clientIdentifier} />}
                            {activeTab === "SETTINGS" && <AccountSettings clientStoreRelationIdentifier={clientIdentifier} />}
                        </div>

                    </div>
                </div>
                <div className={styles.rightWrapper}>
                    <AllVisitsWrapper
                        clientStoreRelationIdentifier={clientIdentifier}
                    />
                </div>
            </div>
        </div>
    )
}

export default EachUserWrapper