import React, { useState } from 'react'
import { FaMinus, FaPlus } from 'react-icons/fa';
import styles from '../Sessions/PriceListCategoryServices.module.css'
import AddOnsSelector from './AddOnsSelector';
import RemoveCartItem from './RemoveCartItem';


const PriceListCategoryServices = props => {
    const sessionIdentifier = props.sessionIdentifier
    const getCartItems = props.getCartItems
    const workersData = props.workersData
    let itemServices = props.itemServices
    const showDiscount = props.showDiscount
    const cartItems = props.cartItems
    const fetchingCartItems = props.fetchingCartItems
    // console.log(itemServices)
    const hasAdded = cartItems.some(item => item.serviceIdentifier === itemServices.identifier)
    let removableItem = [];
    let quantity = 0;
    if (hasAdded) {
        cartItems.map((item) => {
            if (item.serviceIdentifier === itemServices.identifier) {
                quantity = quantity + parseInt(item.quantity)
                removableItem.push(item)
            }
        })
    }

    const [isAddOnOpen, setIsAddOnOpen] = useState(false)
    const [isRemoveOpen, setIsRemoveOpen] = useState(false)

    const addToCartInitiator = () => {
        setIsAddOnOpen(true)
    }
    const abortAddToCart = () => {
        setIsAddOnOpen(false)
    }

    const removeItemInitiator = () => {
        setIsRemoveOpen(true)
    }

    const abortItemRemoval = () => {
        setIsRemoveOpen(false)
    }

    return (
        <div className={styles.mainWrapper}>
            <AddOnsSelector
                isOpen={isAddOnOpen}
                abortAddToCart={abortAddToCart}
                item={itemServices}
                workersData={workersData}
                getCartItems={getCartItems}
                fetchingCartItems={fetchingCartItems}
                sessionIdentifier={sessionIdentifier}
            />
            <RemoveCartItem
                isOpen={isRemoveOpen}
                abortItemRemoval={abortItemRemoval}
                removableItem={removableItem}
                getCartItems={getCartItems}
                fetchingCartItems={fetchingCartItems}
            />
            <div>
                {itemServices.serviceName}
            </div>
            <div>
                <div className={styles.serviceAmout}>
                    <div style={{
                        animation: showDiscount ? `0.2s forwards reduceFontSize` : `0.2s forwards increaseFontSize`
                    }}
                        className={showDiscount ? styles.cutOrgPrice : undefined}
                    >
                        &#8377;{itemServices.basePrice}
                    </div>
                    {showDiscount &&
                        <div className={styles.discountedPrice}>
                            <div style={{ animation: `0.2s forwards increaseFontSize` }}>
                                &#8377;{parseFloat(itemServices.basePrice) - (parseFloat(itemServices.basePrice) * parseFloat(itemServices.offerPercentage !== '' ? itemServices.offerPercentage : 0) / 100)}
                            </div>
                        </div>
                    }
                </div>
                {hasAdded === true ?
                    <div className={styles.modifyQuantity}>
                        <button onClick={() => removeItemInitiator()}>
                            <FaMinus size={12} />
                        </button>
                        <div>
                            {quantity}
                        </div>
                        <button onClick={() => addToCartInitiator()}>
                            <FaPlus size={12} />
                        </button>
                    </div>
                    :
                    <div className={styles.addToCartBtn}>
                        <button onClick={() => addToCartInitiator()} >Add +</button>
                    </div>
                }
            </div>
        </div>
    )
}

export default PriceListCategoryServices