import React, { useContext, useEffect, useState } from 'react'
import { BsDot } from 'react-icons/bs';
import { FaDotCircle } from 'react-icons/fa';
import { GiChoice } from 'react-icons/gi';
import { MdRadioButtonChecked, MdRadioButtonUnchecked } from 'react-icons/md';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import styles from '../Sessions/AddOnsSelector.module.css'
import StaffSelector from './StaffSelector';

const AddOnsSelector = props => {
    const [isAdding, setIsAdding] = useState(false)
    const sessionIdentifier = props.sessionIdentifier
    const getCartItems = props.getCartItems
    const authCtx = useContext(AuthContext)
    const workersData = props.workersData
    let isOpen = props.isOpen;
    const abortAddToCart = props.abortAddToCart;
    const item = props.item;
    const [selectedAddOn, setSelectedAddOn] = useState({
        orgPrice: 0,
        disPrice: 0
    })

    const [consultedBy, setConsultedBy] = useState({
        id: 0,
        name: "Self",
        profilePic: "",
        gender: "Male"
    })
    const [servedBy, setServedBy] = useState()

    const consultedByHandler = (k) => {
        setConsultedBy(k)
    }

    const servedByHandler = (k) => {
        setServedBy(k)
    }

    // console.log(item)

    window.onclick = function (event) {
        if (event.target.id === "addOnSelector") {
            abortAddToCart()
        }

        if (event.target.id == "filtersPanelWrapperMUG") {
            document.getElementById("filtersPanelWrapperMUG").style.animation = "slideRightToLeft .1s forwards";
        }
    };

    useEffect(() => {
        const handleKeyDown = (event) => {
            // Check if Ctrl key or Command key and C key are pressed simultaneously
            if ((event.ctrlKey || event.metaKey) && event.keyCode === 13) {
                event.preventDefault()
                addToCartItem()
            }

            if (event.keyCode == 27) {
                abortAddToCart()
            }
        };

        // Attach the event listener when the component mounts
        document.addEventListener('keydown', handleKeyDown);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [servedBy]);

    const calculateTotalPrice = () => {
        let tOrgPrice = parseFloat(item.basePrice) + parseFloat(selectedAddOn.orgPrice);
        let tDisPrice = parseFloat(item.basePrice - (item.basePrice * item.offerPercentage / 100)) + parseFloat(selectedAddOn.disPrice);

        return { top: parseFloat(tOrgPrice).toFixed(0), tdp: parseFloat(tDisPrice).toFixed(0) }
    }

    const addToCartItem = async () => {
        // console.log(item, selectedAddOn, consultedBy, servedBy)
        let bdp = parseFloat(parseFloat(item.basePrice) - parseFloat(item.basePrice * item.offerPercentage / 100));
        let tadbp = parseFloat(selectedAddOn.orgPrice);

        if (servedBy !== undefined) {
            setIsAdding(true)
            const addCartItemsResponse = await fetch(BASE_URL_API + "addCartItems",
                {
                    method: 'POST',
                    body: JSON.stringify({
                        token: authCtx.token,

                        storeIdentifier: authCtx.storeIdentifier,
                        bookingIdentifier: sessionIdentifier,
                        categoryIdentifier: item.categoryIdentifier,
                        categoryName: item.categoryName,
                        serviceIdentifier: item.identifier,
                        serviceName: item.serviceName,
                        serviceTime: item.serviceTime,
                        genderIdentifier: item.genderIdentifier,
                        genderLabel: item.genderLabel,
                        basePrice: item.basePrice,
                        baseDiscountedPrice: bdp,
                        totalAddOnsBasePrice: tadbp,
                        totalBasePrice: parseFloat(parseFloat(item.basePrice) + parseFloat(selectedAddOn.orgPrice)).toFixed(1),
                        totalDiscPrice: parseFloat(parseFloat(bdp) + parseFloat(selectedAddOn.disPrice)),

                        addOnCategory: selectedAddOn.addOnCategory,
                        addOnType: selectedAddOn.addOnType,
                        addOnOrgPrice: selectedAddOn.orgPrice,
                        addOnDisPrice: selectedAddOn.disPrice,
                        addOnLabel: selectedAddOn?.label !== undefined ? selectedAddOn.label : 'Regular',

                        consultedByIdentifier: consultedBy.id,
                        consultedByName: consultedBy.name,
                        consultedByGender: consultedBy.gender,

                        servedByIdentifier: servedBy.id,
                        servedByName: servedBy.name,
                        servedByGender: servedBy.gender
                    })
                });

            if (!addCartItemsResponse.ok) {
                console.log("Something went wrong : Server Error")
            } else {
                const addCartItemsRespo = await addCartItemsResponse.json();
                if (addCartItemsRespo.status === 'success') {
                    getCartItems()
                    abortAddToCart()
                    document.getElementById("priceListSearchBar").select()
                } else {
                    if (addCartItemsRespo.message === 'tokenExpired') {
                        authCtx.logout()
                    } else {
                        console.log(addCartItemsRespo)
                    }
                }
            }
            setIsAdding(false)
        } else {
            alert("Please select the staff provided services!");
        }
    }


    useEffect(() => {
        setSelectedAddOn({
            orgPrice: 0,
            disPrice: 0
        })

        setConsultedBy({
            id: 0,
            name: "Self",
            profilePic: "",
            gender: "Male"
        })
    }, [isOpen])




    if (!isOpen) {
        return null;
    } else {
        return (
            <div className={styles.mainWrapper}>
                <div className={styles.mainContainer}
                    id="addOnSelector"
                    style={{ animation: `0.12s cubic-bezier(0, 0.63, 0.26, 0.96) 0s 1 normal forwards running slideBottomToTop` }}
                >
                    <div className={styles.mainSelectorBox}>
                        <div className={styles.mainSelectorHeader}>
                            Select the product brand for {item.categoryName}
                        </div>
                        <div className={styles.mainSelectorBody}>
                            {item.addOns?.sort((a, b) => a.orgPrice - b.orgPrice).map((itemAddOns, indexAddOns) => (
                                <div key={itemAddOns.labelId + "0"} className={styles.eachAddOnWrapper}
                                    onClick={() => setSelectedAddOn(itemAddOns)}
                                >
                                    <div className={styles.leftSection}>
                                        {itemAddOns.label}
                                    </div>
                                    <div className={styles.centerSection}>
                                        <div className={styles.addOnOrgPrice}>
                                            &#8377;{itemAddOns.orgPrice}
                                        </div>
                                        <div className={styles.addOnDisPrice}>
                                            &#8377;{itemAddOns.disPrice}
                                        </div>
                                    </div>
                                    <div className={styles.rightSection}>
                                        {itemAddOns === selectedAddOn ?
                                            <MdRadioButtonChecked />
                                            :
                                            <MdRadioButtonUnchecked />
                                        }
                                    </div>
                                </div>
                            ))}

                            <StaffSelector
                                selectingFor="Consulted By"
                                workersData={workersData}
                                selectedStaff={consultedBy}
                                consultedByHandler={consultedByHandler}
                            />
                            <StaffSelector
                                selectingFor="Served By"
                                workersData={workersData}
                                selectedStaff={servedBy}
                                servedByHandler={servedByHandler}
                            />
                        </div>
                        <div className={styles.mainSelectorFooter}>
                            <div>
                                {selectedAddOn !== null &&
                                    <div className={styles.pricesWrapper}>
                                        <div className={styles.top}>
                                            &#8377;{calculateTotalPrice().top}
                                        </div>
                                        <div className={styles.tdp}>
                                            &#8377;{calculateTotalPrice().tdp}
                                        </div>
                                    </div>
                                }
                            </div>
                            <div className={styles.addToCartBtnWrapper}>
                                {isAdding === true ?
                                    <ShimmerThumbnail height={32} width={140} rounded className={styles.noBottomMargin}

                                    />
                                    :
                                    <button onClick={() => addToCartItem()}>
                                        Add To Cart
                                    </button>
                                }
                            </div>
                        </div>

                    </div>
                </div>
            </div>

        )

    }
}

export default AddOnsSelector