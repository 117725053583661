import React, { useContext, useState } from 'react'
import styles from './AccountSettings.module.css'
import { MdDelete } from "react-icons/md";
import { BASE_URL_API } from '../../References/URLs';
import AuthContext from '../../store/auth-context';
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";

const AccountSettings = (props) => {
    const { clientStoreRelationIdentifier } = props
    const authCtx = useContext(AuthContext)
    const [isDeleting, setIsDeleting] = useState(false)
    const nav = useNavigate()

    const deleteUser = async () => {
        setIsDeleting(true)
        const deleteUserResponse = await fetch(BASE_URL_API + "/deleteUser",
            {
                method: "POST",
                body: JSON.stringify({
                    token: authCtx.token,
                    clientStoreRelationIdentifier: clientStoreRelationIdentifier
                })
            });

        if (!deleteUserResponse.ok) {
            console.log("Something went wrong : Server Error")
        } else {
            const deleteUserRespo = await deleteUserResponse.json()
            if (deleteUserRespo.status === "success") {
                toast("Successfully deleted!",
                {
                    onClose : nav(`/users`)
                })
            } else {
                if (deleteUserRespo.message === "tokenExpired") {
                    authCtx.logout()
                }
                toast("Could not delete the client profile!")
            }
        }
        setIsDeleting(false)
    }




    return (
        <div>
            <ToastContainer />
            <div>
                <div className={styles.actionWrapper}>
                    {isDeleting === true ?
                        <div className={styles.eachActionWrapper}
                        >
                            <MdDelete size={20} /> Deleting Now..
                        </div>
                        :
                        <div className={styles.eachActionWrapper}
                            onClick={() => deleteUser()}
                        >
                            <MdDelete size={20} /> Delete User
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default AccountSettings