import React from 'react'
import styles from '../Home/EachBookingAtHome.module.css'
import { Link } from 'react-router-dom';

const EachBookingAtHome = props => {
    let item = props.item;
    return (
        <div className={styles.mainWrapper}>
            <Link to={`/booking/` + item.bookingId}  >
                <div className={styles.clientName}>
                    {item.client_name}
                    <div className={styles.additionalClient}>
                        {parseInt(parseInt(item.maleQuant) + parseInt(item.femaleQuant)) > 1 ? "+" + parseInt(parseInt(parseInt(item.maleQuant) + parseInt(item.femaleQuant)) - 1) + " more" : ""}
                    </div>
                </div>

                <div className={styles.priceSection}>
                    <div className={styles.totalServicesPrice}>
                        <div>
                            Services Total Price
                        </div>
                        <div>
                            &#8377;{parseFloat(item.servicesTotalOrgPrice).toFixed(1)}
                        </div>
                    </div>

                    <div className={styles.allDiscountsWrapper}>
                        <div className={styles.allDiscountsBody}>
                            <div className={styles.eachPriceRow}>
                                <div>
                                    Store discount ({parseFloat((parseFloat(parseFloat(item.servicesTotalOrgPrice) - parseFloat(item.servicesTotalDiscountedPrice))/parseFloat(item.servicesTotalOrgPrice))*100).toFixed(1)}%)
                                </div>
                                <div>
                                    -&#8377;{parseFloat(parseFloat(item.servicesTotalOrgPrice) - parseFloat(item.servicesTotalDiscountedPrice)).toFixed(1)}
                                </div>
                            </div>

                            <div className={styles.eachPriceRow}>
                                <div>
                                    Flat Discount ({item.flatDiscountByPercentage}%)
                                </div>
                                <div>
                                    -&#8377;{parseFloat(item.flatDiscountByAmount).toFixed(1)}
                                </div>
                            </div>
                        </div>

                        <div className={styles.totalDiscountRow}>
                            <div>
                                Total Discount ({item.flatDiscountByPercentage}%)
                            </div>
                            <div>
                                -&#8377;{parseFloat(parseFloat(item.flatDiscountByAmount) + parseFloat(parseFloat(item.servicesTotalOrgPrice) - parseFloat(item.servicesTotalDiscountedPrice))).toFixed(1)}
                            </div>
                        </div>
                    </div>

                    <div className={styles.finalPayRow}>
                        <div className={styles.finalPrice}>
                            <div>
                                To Be collected
                            </div>
                            <div>
                                &#8377;{item.amountToBeCollected}
                            </div>
                        </div>

                    </div>

                </div>



                <div className={styles.paymentWrapper}>
                    <div className={styles.eachPayMode}>
                        <div className={styles.eachPayModeCaption}>
                            Paid By Cash
                        </div>
                        <div>
                            &#8377;{parseFloat(item?.collectionViaCash).toFixed(1)}
                        </div>
                    </div>

                    <div className={styles.eachPayMode}>
                        <div className={styles.eachPayModeCaption}>
                            Paid By QR
                        </div>
                        <div>
                            &#8377;{parseFloat(item?.collectionViaQR).toFixed(1)}
                        </div>
                    </div>

                    <div className={styles.eachPayMode}>
                        <div className={styles.eachPayModeCaption}>
                            Paid By Card
                        </div>
                        <div>
                            &#8377;{parseFloat(item?.collectionViaCard).toFixed(1)}
                        </div>
                    </div>

                    <div className={styles.eachPayMode}>
                        <div className={styles.eachPayModeCaption}>
                            Paid By Gift Card
                        </div>
                        <div>
                            &#8377;{parseFloat(item?.collectionViaGiftCards).toFixed(1)}
                        </div>
                    </div>

                    <div className={styles.eachPayMode}>
                        <div className={styles.eachPayModeCaption}>
                            Paid By Link
                        </div>
                        <div>
                            &#8377;-
                        </div>
                    </div>

                </div>


                <div className={styles.ratingSection}>
                    {item.hasRated === '0' ? 'Customer has not rated the service!' : 'Rating done'}
                </div>

            </Link>
        </div>
    )
}

export default EachBookingAtHome