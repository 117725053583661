import React, { useContext, useState } from 'react'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext from '../../store/auth-context'
import styles from '../../Styles/Components/Services/AddNewService.module.css'

const AddNewService = props => {
    const authCtx = useContext(AuthContext)
    let storeIdentifier = props.storeIdentifier;
    let getServices = props.getServices;
    const [showAddServiceForm, setShowAddServiceForm] = useState(false)

    const [serviceName, setServiceName] = useState('BACK MASSAGE')
    let categoryIdentifier = props.categoryIdentifier; //
    let categoryName = props.categoryName;
    let genderIdentifier = props.genderIdentifier;
    const [basePrice, setBasePrice] = useState('');
    const [serviceTime, setServiceTime] = useState(10);

    const updateBasePriceHandler = (k) => {
        setBasePrice(k);
    }

    const addServiceHandler = async () => {
        // console.log(serviceName, categoryIdentifier, categoryName, genderIdentifier, basePrice, serviceTime);
        if(serviceName!='') {
            if(basePrice!='') {
                if(serviceTime!='') {
                    const addANewServiceResponse = await fetch(BASE_URL_API+"addANewService",
                    {
                        method : "POST",
                        body : JSON.stringify({
                            token : authCtx.token,
                            storeIdentifier : authCtx.storeIdentifier,
                            serviceName : serviceName,
                            categoryIdentifier : categoryIdentifier,
                            categoryName : categoryName,
                            genderIdentifier : genderIdentifier,
                            basePrice : basePrice,
                            serviceTime : serviceTime
                        })
                    });

                    if(!addANewServiceResponse.ok) {
                        console.log("Something went wrong : Server Error")
                    } else {
                        const addANewServiceRespo = await addANewServiceResponse.json();
                        if(addANewServiceRespo.status==="success") {
                            getServices();
                        } else {

                        }
                        console.log(addANewServiceRespo.status);
                    }
                } else {
                    alert("Please select a valid Time")
                }
            } else {
                alert("Please enter a valid price...");
            }
        } else {
            alert("Please add service name...")
        }
    }
    return (
        <div className={styles.mainContainer}>
            {showAddServiceForm == false ?
                <button className={styles.addANewServiceBtn}
                    onClick={() => setShowAddServiceForm(true)}
                >
                    Add A New Service
                </button>
                :
                <div className={styles.sampleServicesContainer}>
                    
                    
                    <div className={styles.eachSampleService}>
                        <div className={styles.essTitle}>
                            <input placeholder="Service Name..." 
                                onChange={(e) => setServiceName(e.target.value)}
                                value={serviceName}
                            />
                        </div>
                        <div className={styles.essDuration}>
                            <select>
                                <option value="">Time</option>
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="30">30</option>
                                <option value="40">40</option>
                                <option value="50">50</option>
                                <option value="60">60</option>
                                <option value="70">70</option>
                                <option value="80">80</option>
                            </select>
                        </div>
                        <div className={styles.essPrice}>
                            <input placeholder="Price" 
                                onChange={(e) => updateBasePriceHandler(e.target.value)}
                                value={basePrice}
                            />
                        </div>
                        <div className={styles.essBtn}>
                            <button onClick={() => addServiceHandler()} >Add +</button>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default AddNewService