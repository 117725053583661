import React, { useContext, useEffect, useState } from 'react'
import { FaUser } from 'react-icons/fa'
import { BASE_URL_API } from '../../References/URLs'
import AuthContext, { AuthContextProvider } from '../../store/auth-context'
import styles from '../Sessions/StaffSelector.module.css'

const StaffSelector = props => {
    const authCtx = useContext(AuthContext)
    const selectingFor = props.selectingFor
    const workersData = props.workersData

    const consultedByHandler = props.consultedByHandler
    const selectedStaff = props.selectedStaff
    const servedByHandler = props.servedByHandler


    return (
        <div className={styles.mainWrapper}>
            <div className={styles.title}>
                {selectingFor}
            </div>
            <div className={styles.stylistListWrapper}>
                {selectingFor === 'Consulted By' &&
                    <div className={selectedStaff?.id === 0 ? styles.eachWBoxSelected : styles.eachWBox} onClick={() => selectingFor === 'Consulted By' ? consultedByHandler({
                        id: 0,
                        name: "Self",
                        profilePic: "",
                        gender: "Male"
                    }) : servedByHandler({
                        id: 0,
                        name: "Self",
                        profilePic: "",
                        gender: "Male"
                    })} >
                        Self
                    </div>
                }
                {workersData.map((item, index) => (
                    <div key={index} className={selectedStaff === item ? styles.eachWBoxSelected : styles.eachWBox} onClick={() => selectingFor === 'Consulted By' ? consultedByHandler(item) : servedByHandler(item)} >
                        <div className={styles.staffProfilePic}>
                            {item.profilePic !== '' ?
                                <img src={item.profilePic} />
                                :
                                <FaUser size={32} color="#c1c1c1" />
                            }
                        </div>
                        <div className={styles.name}>
                            {item.name}
                        </div>
                    </div>
                ))}

            </div>


        </div>
    )
}

export default StaffSelector